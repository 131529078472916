<script>
export default {
  name: 'Breadcrumbs',
  computed: {
  breadcrumbs() {
    let routeArray = this.$route.path.split('/').filter(path => path)
    let breadcrumbs = routeArray.map((path, index) => {
      let route = this.$router.options.routes.find(route => route.path === '/' + routeArray.slice(0, index + 1).join('/'))
      return {
        name: route && route.meta ? route.meta.breadcrumb : path.charAt(0).toUpperCase() + path.slice(1),
        path: '/' + routeArray.slice(0, index + 1).join('/')
      }
    })
    return breadcrumbs
  }
}
}
</script>

<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb-list">
      <li v-for="(breadcrumb, index) in breadcrumbs" :key="index" class="breadcrumb-text" >
        <p class="route"> >
          <span class="route-breadcrumb" v-if="breadcrumb.name == 'Trips'">
            {{ $t("components.views.trip.active.breadcrumb.route_one") }}
          </span>
          <span class="route-breadcrumb" v-if="breadcrumb.name == 'Active'">
            {{ $t("components.views.trip.active.breadcrumb.route_two") }}
          </span>
        </p>
      </li>
    </ol>
  </nav>
</template>

<style scoped>
@import './BreadCrumbStyle.scss';

.breadcrumb-list {
    display: flex;
    list-style: none;
    padding: 0;
  }

  .route {
    color: blue;
    margin: 0;
  }
</style>
